<template>
  <div
    v-if="categoryState !== null"
    class="category-edit pl-10"
  >
    <span
      v-if="categoryState"
      class="category_list__title"
    >
      {{ categoryState === 'edit' ?
        `Категория "${selectedCategory.title}"` :
        'Новая категория' }}
    </span>
    <NamedRuledTextField
      class="category-title"
      description="Введите название категории которое будет отображаться для пользователей в
      мобильном приложении и на сайте. Название должно состоять из одного слова"
      description-width="6"
      inner-wrapper-width="7"
      :text="selectedCategory.title"
      title="Название категории на русском"
      title-width="4"
      @change="selectedCategory.title = $event"
    />
    <NamedRuledTextField
      class="category-title"
      description="Введите название категории которое будет отображаться для пользователей в
      мобильном приложении и на сайте. Название должно состоять из одного слова"
      description-width="6"
      inner-wrapper-width="7"
      :text="selectedCategory.titleKz"
      title="Название категории на казахском"
      title-width="4"
      @change="selectedCategory.titleKz = $event"
    />
    <NamedRuledTextField
      class="category-position"
      description="Позиция категории - положение категории заведения в общем списке по порядку:
      “1” - первая категория слева направо, далее все последующие."
      description-width="6"
      inner-wrapper-width="7"
      :text="selectedCategory.position"
      title="Позиция"
      title-width="4"
      @change="selectedCategory.position = $event"
    />
    <ImageUpload
      :allow-preview="false"
      description="Скопируйте ссылку на месторасположение изображения категории,
            либо загрузите изображение в формате PNG, SVG размером 58x58px"
      disable-padding
      :image="selectedCategory.activeIcon"
      image-right
      image-type="category"
      left-col="4"
      title="Иконка 1 (активное состояние)"
      wrapper-cols="12"
      @update="activeIconUploaded"
    />
    <ImageUpload
      :allow-preview="false"
      description="Скопируйте ссылку на месторасположение изображения категории,
            либо загрузите изображение в формате PNG, SVG размером 58x58px"
      disable-padding
      :image="selectedCategory.inActiveIcon"
      image-right
      image-type="category"
      left-col="4"
      title="Иконка 2 (неактивное состояние)"
      wrapper-cols="12"
      @update="inactiveIconUploaded"
    />
    <ImageUpload
      :allow-preview="false"
      description="Скопируйте ссылку на месторасположение изображения категории,
            либо загрузите изображение в формате PNG, SVG размером 58x58px"
      disable-padding
      :image="selectedCategory.imageForDigitalRestaurants"
      image-right
      image-type="category"
      left-col="4"
      title="Картинка (для Digital Restaurant)"
      wrapper-cols="12"
      @update="digitalRestaurantUploaded"
    />
    <VDivider class="pl-3" />
    <div class="d-flex justify-center mt-3">
      <VBtn
        class="my-0 delete-btn white--text ma-3"
        color="red"
        rounded
        @click="deleteCategoryAndUpdateSelected()"
      >
        <span class="add-category-text ml-3 mr-3">Удалить</span>
      </VBtn>
      <VBtn
        class="my-0 cancel-btn white--text ma-3"
        color="black"
        rounded
        @click="selectCategory({})"
      >
        <span class="add-category-text ml-3 mr-3">Отменить</span>
      </VBtn>
      <VBtn
        class="my-0 save-btn white--text ma-3"
        color="green"
        rounded
        @click="createOrUpdateCategory()"
      >
        <span class="add-category-text ml-3 mr-3">Сохранить</span>
      </VBtn>
    </div>
  </div>
</template>

<script>
import { createCategory, updateCategory } from '@/api/api';
import { eventHub, events } from '@/global/eventhub';
import { Category } from '@/serializers/categorySerializer';
import ImageUpload from '@/components/ImageUpload.vue';
import NamedRuledTextField from '@/components/NamedRuledTextField.vue';

export default {
  name: 'CategoryEdit',
  components: { NamedRuledTextField, ImageUpload },
  props: {
    categories: {
      type: Array,
      required: true,
    },
  },
  emits: ['delete'],
  data() {
    return {
      categoriesToEdit: [...JSON.parse(JSON.stringify(this.categories))],
      categoryState: null,
      selectedCategory: new Category(),
    };
  },
  created() {
    eventHub.$on(events.categoryState, (data) => {
      this.categoryState = data;
    });
    eventHub.$on(events.categorySelected, (data) => {
      this.selectedCategory = data;
    });
  },
  methods: {
    emitCategoryStateToNull() {
      this.categoryState = null;
      eventHub.$emit(events.categoryState, null);
    },
    emitSelectedCategory(category) {
      this.selectedCategory = category;
      eventHub.$emit(events.categorySelected, category);
    },
    emitCategories() {
      eventHub.$emit(events.categoryList, this.categoriesToEdit);
    },
    selectCategory(category) {
      this.emitCategoryStateToNull();
      this.emitSelectedCategory(category);
    },
    pushToCategories(category) {
      this.categoriesToEdit.push(category);
      this.categoriesToEdit.sort((a, b) => (a.position - b.position));
      this.emitCategories();
    },
    createOrUpdateCategory() {
      if (this.categoryState === 'new') {
        createCategory(this.selectedCategory).then((result) => this.pushToCategories(result));
      } else {
        updateCategory(this.selectedCategory.id, this.selectedCategory);
      }
      this.selectCategory(new Category());
    },
    deleteCategoryAndUpdateSelected() {
      if (this.categoryState === 'edit') {
        this.$emit('delete', this.selectedCategory);
      } else {
        this.selectCategory(new Category());
      }
    },
    activeIconUploaded(imageURL) {
      this.selectedCategory.activeIcon = imageURL.logo || null;
    },
    inactiveIconUploaded(imageURL) {
      this.selectedCategory.inActiveIcon = imageURL.logo || null;
    },
    digitalRestaurantUploaded(imageURL) {
      this.selectedCategory.imageForDigitalRestaurants = imageURL.logo || null;
    },
  },
  watch: {
    categories(val) {
      this.categoriesToEdit = [...JSON.parse(JSON.stringify(val))];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/page.scss";

.category-edit {
  position: static;
  width: 70%;
}
.category_icon {
  width: 5vw;
  margin-left: 30px;
}
.category_list__title {
  font-family: 'AvenirNextCyr Black';
  font-size: 20pt;
}
.category-name-field {
  width: 50%;
}

.category-name-text {
  width: 72%;
}
.text-field {
  width: 60%;
}
.item-title-text {
  font-family: 'AvenirNextCyr Black';
  font-size: 13px;
  width: 30%;
  display: inline-block;
  cursor: pointer;
}
</style>
