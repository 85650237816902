<template>
  <VContainer fluid>
    <DeletionPopUp
      v-if="deletionPopup"
      :title="popUpTitle"
      @confirm="confirmDelete"
    />
    <div class="search_category d-flex align-center pb-2 pl-4">
      <span class="search__title pr-4">Поиск</span>
      <div class="search_category__title">
        <form @submit.prevent="fetchByCategoryTitle()">
          <VTextField
            v-model="categoryTitle"
            append-icon="mdi-magnify"
            autofocus
            background-color="#F2F2F2"
            color="green"
            dense
            hide-details
            outlined
            placeholder="Введите название категории..."
          />
        </form>
      </div>
    </div>
    <div class="category_list pl-4 mb-2">
      <span class="category_list__title pr-2">Категории заведений</span>
      <div class="d-flex pa-4 align-center">
        <VCarousel
          color="green"
          height="200"
          hide-delimiter-background
          hide-delimiters
          light
          :show-arrows="categoriesForSlider.length > 1"
          show-arrows-on-hover
        >
          <VCarouselItem
            v-for="category in categoriesForSlider"
            :key="category[0] ? category[0].title : null"
          >
            <VSheet height="100%">
              <VRow
                align="center"
                class="fill-height"
                justify="center"
              >
                <div
                  v-for="eachCategory in category"
                  :key="eachCategory.title"
                  class="display-4 category_list__object d-flex flex-column mx-6"
                  @click="showClickedCategory(eachCategory)"
                >
                  <img
                    alt="category_icon"
                    class="category_icon"
                    :src="eachCategory.activeIcon"
                  />
                  <span class="category_icon__title">{{
                    eachCategory.title
                  }}</span>
                </div>
              </VRow>
            </VSheet>
          </VCarouselItem>
        </VCarousel>
      </div>
    </div>
    <VBtn
      class="my-0 white--text mb-4 category-create-btn"
      color="green"
      rounded
      @click="addNewCategory()"
    >
      <VIcon dark>
        mdi-plus-circle
      </VIcon>
      <span class="add-category-text ml-1">Добавить категорию</span>
    </VBtn>

    <div class="category d-flex">
      <div class="category-list">
        <CategoryList :categories="categories" @delete="prepareDeletion" />
      </div>
      <VDivider class="pl-3" vertical />
      <CategoryEdit :categories="categories" @delete="prepareDeletion" />
    </div>
  </VContainer>
</template>

<script>
import { eventHub, events } from '@/global/eventhub';
import deletionPopUp from '@/components/DeletionPopUp.vue';
import CategoryList from '@/components/CategoryList.vue';
import CategoryEdit from '@/components/CategoryEdit.vue';
import { Category } from '@/serializers/categorySerializer';
import { fetchCategories, deleteCategory } from '@/api/api';

export default {
  data() {
    return {
      newCategory: false,
      categoryTitle: '',
      categories: [],
      countOfElementsInSlider: 9,
      deletionPopup: false,
      popUpTitle: '',
      categoryToDelete: null,
    };
  },
  created() {
    this.fetchByCategoryTitle();
    eventHub.$on(events.categoryList, (data) => {
      this.categories = data;
    });
  },
  components: {
    CategoryList,
    CategoryEdit,
    deletionPopUp,
  },
  methods: {
    prepareDeletion(category) {
      this.categoryToDelete = category;
      this.deletionPopup = true;
      this.popUpTitle = category.title;
    },
    confirmDelete(selection) {
      if (selection === 'delete') {
        deleteCategory(this.categoryToDelete.id).then(() => {
          this.$amplitudeEvent('category_deleted', this.categoryToDelete.title);
          this.fetchByCategoryTitle();
        });
      }
      this.deletionPopup = false;
      this.popUpTitle = '';
    },
    emitCategoryState(state) {
      eventHub.$emit(events.categoryState, state);
    },
    emitSelectedCategoryToNew() {
      eventHub.$emit(events.categorySelected, new Category());
    },
    addNewCategory() {
      this.emitSelectedCategoryToNew();
      this.emitCategoryState('new');
    },
    fetchByCategoryTitle() {
      fetchCategories(this.categoryTitle).then((result) => {
        this.categories = result;
        this.categories.sort((a, b) => a.position - b.position);
      });
      this.emitSelectedCategoryToNew();
      this.emitCategoryState(null);
    },

    showClickedCategory(category) {
      this.emitCategoryState('edit');
      eventHub.$emit(events.categorySelected, category);
    },
  },
  computed: {
    categoriesForSlider() {
      const ELEMENTS_COUNT = this.countOfElementsInSlider;
      const arrToSlice = [...this.categories];
      const parted = [];
      let iterationCount = Math.round(arrToSlice.length / ELEMENTS_COUNT);
      iterationCount += arrToSlice.length % ELEMENTS_COUNT >= 1 ? 1 : 0;
      for (let i = 0; i < iterationCount; i += 1) {
        const sliced = arrToSlice.slice(
          i * ELEMENTS_COUNT,
          i * ELEMENTS_COUNT + ELEMENTS_COUNT,
        );
        if (sliced.length) {
          parted.push(sliced);
        }
      }
      return parted;
    },
  },
};
</script>

<style scoped lang="scss">
* {
  text-transform: none !important;
}

::v-deep {
  .v-progress-linear {
    height: 2px !important;
    width: 20%;
    margin: 0 auto;
  }
}
.category_list__wrap {
  width: 100%;
}
.category_list__object {
  width: 5%;
  margin: 0 3px;
  cursor: pointer;
  transition: transform 0.3s;
  &:hover {
    transform: scale(1.03);
  }
}
.v-text-field {
  padding-top: 0;
}
.text-field {
  width: 60%;
}
.category-list {
  width: 27%;
}
.category_icon {
  width: 100%;
  max-height: 65px;
}
.category_icon__title {
  font-family: 'AvenirNextCyr';
  font-size: 14px;
  text-align: center;
  height: 15px;
}
.search__title {
  font-family: 'AvenirNextCyr Black';
  font-size: 13pt;
}
.category_list__title {
  font-family: 'AvenirNextCyr Black';
  font-size: 20pt;
}
.search_category__title {
  font-size: 12px;
  width: 40%;
  color: rgb(0, 0, 0, 0.5);
}
.order-title {
  font-size: 15pt;
}
.item-id-title {
  font-family: 'AvenirNextCyr Black';
  font-size: 13px;
  align-self: center;
}

.add-category-text {
  font-family: 'AvenirNextCyr Black';
  font-size: 13px;
  letter-spacing: -0.5px;
}
</style>
