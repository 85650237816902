<template>
  <VList class="overflow-y-auto" max-height="600px">
    <div
      v-if="categoryState === 'new'"
      class="flex-container"
    >
      <div
        class="cols cols-color item-id active"
      >
        <span class="item-id-title pa-2">#</span>
      </div>
      <div class="cols px-1"></div>
      <div
        class="cols item-title active"
      >
        <div class="item-title-text new-category-title">
          Новая категория
        </div>
        <div class="arrow pl-0">
          <VIcon color="black" large>
            mdi-chevron-right
          </VIcon>
        </div>
        <div class="my-0 arrow pl-0">
          <VBtn
            color="red"
            dark
            fab
            x-small
            @click="emitCategoryStateToNull()"
          >
            <VIcon>mdi-delete</VIcon>
          </VBtn>
        </div>
      </div>
    </div>
    <div
      v-for="(category, index) in categories"
      :key="category.id"
      class="col pl-0 py-2"
    >
      <div class="flex-container">
        <div
          class="cols cols-color item-id"
          :class="{ active: index === selectedCategory.index}"
        >
          <span class="item-id-title pa-2">
            {{ category.position }}
          </span>
        </div>
        <div class="cols px-1"></div>
        <div
          class="cols item-title"
          :class="{ active: index === selectedCategory.index }"
        >
          <div
            class="item-title-text"
            @click="selectCategory(category, index)"
          >
            {{ category.title }}
          </div>
          <div class="arrow pl-0" @click="selectCategory(category, index)">
            <VIcon color="black" large>
              mdi-chevron-right
            </VIcon>
          </div>
          <div class="my-0 arrow pl-0">
            <VBtn
              class="delete-category-btn"
              color="red"
              dark
              fab
              x-small
              @click="prepareDeletion(category, index)"
            >
              <VIcon>mdi-delete</VIcon>
            </VBtn>
          </div>
        </div>
      </div>
    </div>
  </VList>
</template>

<script>
import { eventHub, events } from '@/global/eventhub';
import { Category } from '@/serializers/categorySerializer';

export default {
  name: 'CategoryList',
  emits: ['delete'],
  data() {
    return {
      categoryState: null,
      selectedCategory: new Category(),
    };
  },
  props: {
    categories: {
      type: Array,
      required: true,
    },
  },
  created() {
    eventHub.$on(events.categoryState, (data) => {
      this.categoryState = data;
    });
    eventHub.$on(events.categorySelected, (data) => {
      this.selectedCategory = data;
    });
  },
  methods: {
    emitCategoryStateToEdit() {
      this.categoryState = 'edit';
      eventHub.$emit(events.categoryState, 'edit');
    },
    emitCategoryStateToNull() {
      this.categoryState = null;
      eventHub.$emit(events.categoryState, null);
    },
    emitSelectedCategory(category) {
      this.selectedCategory = category;
      eventHub.$emit(events.categorySelected, category);
    },
    selectCategory(category, index = null) {
      this.emitCategoryStateToEdit();
      const categoryToSelect = category;
      categoryToSelect.index = index;
      this.emitSelectedCategory(categoryToSelect);
    },
    prepareDeletion(categoryToDelete) {
      this.$emit('delete', categoryToDelete);
    },
  },
};
</script>

<style lang="scss">
.columns {
  display: flex;
}

.flex-container {
  display: flex;
}

.cols {
  padding: 5px;
}

.cols:nth-of-type(1) {
  flex: 0.25;
  text-align: center;
}

.cols:nth-of-type(odd) {
  border-radius: 3px;
  background: #EFEFEF;
  &.active {
    background: #4CAF50;
    color: white;
  }
}

.flex-container:hover .item-id {
  background: #4CAF50;
  color: white;
}

.item-title-text {
  font-family: 'AvenirNextCyr Black';
  font-size: 13px;
  width: 30%;
  display: inline-block;
  cursor: pointer;
}

.flex-container:hover .item-title {
  background: #4CAF50;
  color: white;
}

.flex-container:hover .v-icon {
  color: white !important;
}

.cols:nth-of-type(3) {
  flex: 4;
}

.arrow {
  display: inline-block;
  float: right;
  cursor: pointer;
}
</style>
